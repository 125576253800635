import React, { useEffect, useRef, useState } from "react";
import CartoonVid from "../assets/videos/istanbulMabsan02LQ.mp4";
import PrensVid from "../assets/videos/Map_V18.mp4.mp4";
import FactoryEFSO from "../assets/factory/fabrikaEFSOkirpik.png";
import FactoryMABSAN from "../assets/factory/fabrikaMabsanKirpik.png";
import FactoryMERS from "../assets/factory/fabrikaMERSkirpik.png";
import FactoryMESRLOGISTIC from "../assets/factory/fabrikaMersLojistikKirpik.png";
import "../styles/style.css";

export default function VideoPlayer() {
  const [isCartoonFinished, setIsCartoonFinished] = useState(false);
  const videoRef = useRef(null); // Videoya referans
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0, topOffset: 0, leftOffset: 0 });

  const handleVideoEnd = () => {
    setIsCartoonFinished(true); // İlk video bittiğinde ikinci video başlasın
  };

  // Aspect ratio'yu kullanarak kırpılan alanları hesaplayan fonksiyon
  const updateVideoDimensions = () => {
    if (videoRef.current) {
      const videoElement = videoRef.current;
      const videoAspectRatio = videoElement.videoWidth / videoElement.videoHeight;
      const windowAspectRatio = window.innerWidth / window.innerHeight;

      let newWidth, newHeight, leftOffset = 0, topOffset = 0;

      if (windowAspectRatio > videoAspectRatio) {
        // Pencere genişse: Video üst ve alt kısımdan kırpılır
        newWidth = window.innerWidth;
        newHeight = window.innerWidth / videoAspectRatio;
        topOffset = (newHeight - window.innerHeight) / 2; // Üstten/Alttan kırpma miktarı
      } else {
        // Pencere uzunsa: Video yanlardan kırpılır
        newHeight = window.innerHeight;
        newWidth = window.innerHeight * videoAspectRatio;
        leftOffset = (newWidth - window.innerWidth) / 2; // Soldan/Sağdan kırpma miktarı
      }

      setVideoDimensions({
        width: newWidth,
        height: newHeight,
        topOffset: topOffset,
        leftOffset: leftOffset
      });
    }
  };

  useEffect(() => {
    updateVideoDimensions(); // Video boyutlarını hesapla
    window.addEventListener("resize", updateVideoDimensions); // Pencere yeniden boyutlandırıldığında boyutları güncelle
    return () => window.removeEventListener("resize", updateVideoDimensions); // Temizle
  }, []);

  return (
    <div className="video-container">
      {/* İlk video */}
      {!isCartoonFinished && (
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          onEnded={handleVideoEnd}
          playbackRate={2}
          className="background-video"
          onLoadedMetadata={updateVideoDimensions}
          style={{
            width: `${videoDimensions.width}px`,
            height: `${videoDimensions.height}px`,
            top: `-${videoDimensions.topOffset}px`,
            left: `-${videoDimensions.leftOffset}px`,
            position: 'absolute',
          }}
        >
          <source src={CartoonVid} type="video/mp4" />
        </video>
      )}

      {/* İkinci video */}
      {isCartoonFinished && (
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          loop
          className="background-video"
          onLoadedMetadata={updateVideoDimensions} // İkinci videonun boyutları da güncellenmeli
          style={{
            width: `${videoDimensions.width}px`,
            height: `${videoDimensions.height}px`,
            top: `-${videoDimensions.topOffset}px`,
            left: `-${videoDimensions.leftOffset}px`,
            position: 'absolute',
          }}
        >
          <source src={PrensVid} type="video/mp4" />
        </video>
      )}

      {/* PNG görselleri yalnızca ikinci video başladığında gösterilecek */}
      {isCartoonFinished && (
        <>
          <img
            src={FactoryEFSO}
            className="factory efso"
            style={{
              top: `${(videoDimensions.height * 0.625) - (videoDimensions.height * 0.15) - videoDimensions.topOffset}px`,
              left: `${(videoDimensions.width * 0.812) - (videoDimensions.width * 0.09) - videoDimensions.leftOffset}px`,
              width: `${videoDimensions.width * 0.18}px`
            }}
            alt="EFSO Factory"
          />
          <img
            src={FactoryMABSAN}
            className="factory mabsan"
            style={{
              top: `${(videoDimensions.height * 0.012) - videoDimensions.topOffset}px`,
              left: `${(videoDimensions.width * 0.26) - videoDimensions.leftOffset}px`,
              width: `${videoDimensions.width * 0.165}px`
            }}
            alt="MABSAN Factory"
          />
          <img
            src={FactoryMERS}
            className="factory mers"
            style={{
              top: `${(videoDimensions.height * 0.015) - videoDimensions.topOffset}px`,
              left: `${(videoDimensions.width * 0.588) - videoDimensions.leftOffset}px`,
              width: `${videoDimensions.width * 0.20}px`
            }}
            alt="MERS Factory"
          />
          <img
            src={FactoryMESRLOGISTIC}
            className="factory logistic"
            style={{
              top: `${(videoDimensions.height * 0.55) - videoDimensions.topOffset}px`,
              left: `${(videoDimensions.width * 0.177) - videoDimensions.leftOffset}px`,
              width: `${videoDimensions.width * 0.16}px`
            }}
            alt="MERS Logistics"
          />
        </>
      )}
    </div>
  );
}
