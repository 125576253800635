import logo from './logo.svg';
import './App.css';
import  MainPage from './components/mainPage';


function App() {

  return (   
  <MainPage />  
  )
}

export default App;
