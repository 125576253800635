import React from "react";
import { useState, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader"
import VideoPlayer from "./VideoPlayer";
import Clickable from "./clickable";



export default function MainPage (){

    const [loading, setLoading] = useState(false)  
    useEffect(() =>{
      var loader = document.getElementById("load");
      
      loader.classList.add("loader");
      setLoading(true)   
      setTimeout(()=> {
        setLoading(false);
     var loader = document.getElementById("load");
     loader.classList.remove("loader");    
  
      },2600)
    },[])





    return(     
        <div  id="load" className="App loader m-0">              
        {
          loading ?    
    <BarLoader  
  color="#2371b0"
  cssOverride={{}}
  height={7}
  loading
  speedMultiplier={1}
  width={"100vw"}
/> 
          :
                
        <VideoPlayer />   
        
        
        }    
        {/* <Clickable />    */}
        </div>
    )    
    
}

